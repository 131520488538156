import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import { H1, Text } from '../components/Typography'
import { Box, Flex } from '../components/Layout'
import HeaderLean from '../components/HeaderLean'
import BlockOfTextWithTableOfContents from '../components/BlockOfTextWithTableOfContents'
import Footer from '../components/Footer'

const content = [
  {
    name: 'communityguidelines',
    title: 'Community Guidelines',
    body: `
      Feedback and contributions from real agency customers are cornerstones of Agency Vista. In order to ensure our platform remains fair and provides valuable information, please review our guidelines below before leaving a review, comment, or information request.
      <br>
      You are entitled and encouraged to be honest and frank in your feedback about your relationships with agencies on our platform. However, using the Agency Vista review functionality to demand payment or remediation is not an appropriate use of the platform and any related content will be removed. Agency Vista reserves the right to revoke status or remove profiles from our platform who are found in violation of these guidelines at any time.
      `,
  },
  {
    name: 'businessrelationship',
    title: 'Business Relationship',
    body: `
      To ensure that comments and reviews are relevant to the content of our platform, we require that you have a prior or existing business relationship with an agency (as a client or vendor) to leave a review. Any reviews found to be in violation of this guideline will be removed.
      `,
  },
  {
    name: 'inappropriatecontent',
    title: 'Inappropriate Content',
    body: `
      We encourage you to speak your mind and be honest with your feedback and comments, but hate speech, lewd or demeaning language, threats, and harassment will not be tolerated whatsoever.
      `,
  },
  {
    name: 'relevance',
    title: 'Relevance',
    body: `
      Before submitting or posting any comments or reviews on a profile, ensure that your remarks are relevant to the business and appropriate for Agency Vista. Using reviews to discuss something such as an agency's political beliefs, hiring behaviors, or affiliations do not address the core goals of the Agency Vista platform and will be removed.
      `,
  },
  {
    name: 'privacy',
    title: 'Privacy',
    body: `
      Divulging personal information or other sensitive details about an agency or its employees will not be tolerated, and any identifying information will be removed.
      `,
  },
  {
    name: 'biasconflictofinterest',
    title: 'Bias & Conflict Of Interest',
    body: `
      Comments or reviews should be objective, fact-based, and without bias. This includes writing self-reviews, submitting reviews for a business owned by a friend or family member, or submitting reviews of your competitors’ businesses.
      `,
  },
  {
    name: 'selfpromotionalcontent',
    title: 'Self-Promotional Content',
    body: `
      Your agency profile is your place to show your business personality and have a little bit of fun. However, your profile is not to be used as a promotion and marketing tool. Things like coupon codes, discount links, or other promotional materials should not be used in your profile information and will be removed.
      `,
  },
  {
    name: 'originalityauthenticity',
    title: 'Originality & Authenticity',
    body: `
      Any comments or reviews submitted through Agency Vista should be original & come from their original source. Content & reviews that are found elsewhere online or otherwise duplicated will be removed.
      `,
  },
  {
    name: 'exploitationremediation',
    title: 'Exploitation & Remediation',
    body: `
      You are entitled and encouraged to be honest and frank in your feedback about your relationships with agencies on our platform. However, using Agency Vista to demand payment or remediation is not an appropriate use of the platform and any related content will be removed.
      `,
  },
  {
    name: 'integritypolicyforserviceproviders',
    title: 'Agency Vista Integrity Policy For Service Providers',
    body: `
      The success of Agency Vista is dependent on Agency Vista’s ability to help businesses identify agencies they can trust. That trust is built on the credibility and recommendation of the businesses that participate in the Agency Vista community. This Integrity Policy outlines the expectations for agencies that claim their profile on Agency Vista, and, further, for those agencies that receive an Agency Vista verified badge.
      `,
  },
  {
    name: 'theagencyvistacommunity',
    title: 'The Agency Vista Community',
    body: `
      By claiming your profile on Agency Vista, you agree to maintain the following qualifications as a recommendable service provider:
      <br>
      <ul>
            <li>Location: Businesses frequently use Agency Vista to identify providers in their local markets. Prior to claiming your profile, Agency Vista analysts will verify your location via your Google My Business listing. For those providers that have multiple cities, Agency Vista will review Google My Business listings for each location and verify that the agency has in-market resources.</li>
            <li>Services Provided: In addition to location, businesses seek to find providers on Agency Vista by service provided, for example, social media marketing, email marketing, search engine optimization, etc. Agency Vista analysts will verify your services provided as detailed on your website and may request additional information if necessary to verify the services you provide. Service representation online or with additional information requested must persist at all times in order to be represented on all lists with the ability of Agency Vista to remove the customer from lists if they do not find evidence of offering such services.</li>
            <li>Profile Completion: Agency Vista uses the information you provide on your profile to highlight the agencies to businesses that best suit their needs. Members of the Agency Vista community must understand a profile is your place to showcase your work and highlight your company’s culture. However, your profile is not to be used as a promotion and marketing tool. Things like coupon codes, discount links, or other promotional materials should not be used in your profile information and will be removed.</li>
            <li>Review Quality: At Agency Vista, we take the validity of our reviews very seriously. Help us fight fake reviews; all reviews must meet the Review Quality Standards detailed below.</li>
      </ul>
      `,
  },
  {
    name: 'verifiedagencybadge',
    title: 'Verified Agency Badge',
    body: `
      By receiving the verified agency badge on Agency Vista you will be joining a global community of specialists known for excellent service and technical skills. An Agency Vista verified badge celebrates businesses with the highest brand integrity and client satisfaction based on your profile information. As a verified agency, you agree to maintain the qualifications listed above for the Agency Vista Community.
      `,
  },
]

const CommunityGuidelines = () => (
  <App isHeaderVisible={false}>
    <SEO
      title="Community Guidelines"
      description="Learn more about the community guidelines for Agency Vista"
      path="/guidelines/"
    />
    <HeaderLean />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1>Community Guidelines</H1>
      {/*<Text mt="m" fontSize="xs" color="secondaryText">*/}
      {/*  Effective October 1st, 2019*/}
      {/*</Text>*/}
    </Flex>
    <Box m="0 auto" px="m" mt="xl" maxWidth="1080px">
      <BlockOfTextWithTableOfContents content={content} />
    </Box>
    <Box my="xl">
      <Footer />
    </Box>
  </App>
)

export default CommunityGuidelines
